var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"1500px","icon":"mdi-cellphone-dock","color":"primary","title":_vm.$t(_vm.entity + '.TITLE'),"text":_vm.$t(_vm.entity + '.SUBTITLE')}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","search":_vm.search,"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"sort-by":"calories","page":_vm.page,"items-per-page":_vm.$store.state.itemsPerPage},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":function($event){_vm.selectedCommentWithoutResponse = item;
              _vm.dialog = true;}}},[_c('v-icon',[_vm._v("mdi-checkbox-multiple-marked")])],1),(_vm.rolPermisos['Delete'])?_c('v-btn',{attrs:{"color":"error","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v(_vm._s(_vm.$t("users.NO_DATA")))])]},proxy:true},{key:"item.description",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))]}},{key:"item.fanpage",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$store.state.botsModule.bots.find( function (el) { return el.fanpageId == item.fanpageId; } ) ? _vm.$store.state.botsModule.bots.find( function (el) { return el.fanpageId == item.fanpageId; } ).name : "SIN PAGINA"))]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Activo")]):_c('v-chip',{attrs:{"color":"error"}},[_vm._v("Inactivo")])]}}],null,true)}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.items.length ? _vm.items.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.items.length)+" registros ")])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-update")]),_c('span',{staticClass:"headline"},[_vm._v("Asignar a publicación existente")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Filtrar por productos")]),_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"_id","search-input":_vm.searchProduct,"items":_vm.products,"chips":"","dense":"","clearable":"","label":"Busca los productos","no-data-text":"No se encontraron productos","no-filter":"","solo":"","outlined":"","hide-details":"","multiple":_vm.editedIndex > -1 ? false : true},on:{"update:searchInput":function($event){_vm.searchProduct=$event},"update:search-input":function($event){_vm.searchProduct=$event},"change":_vm.onSelectedProducts},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var attrs = ref.attrs;
                var item = ref.item;
                var select = ref.select;
                var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":"","color":"deep-purple accent-4","outlined":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item.name))])])]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Subir imagen/video de publicación a multimedia")]),_c('v-checkbox',{attrs:{"label":"Subir a multimedia"},model:{value:(_vm.editedItem.hasToUploadToMultimedia),callback:function ($$v) {_vm.$set(_vm.editedItem, "hasToUploadToMultimedia", $$v)},expression:"editedItem.hasToUploadToMultimedia"}})],1),_c('v-col',{staticClass:"mt-3 mb-3",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v("Selección para respuesta")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Fecha")]),_c('th',{staticClass:"text-left"},[_vm._v("Url")]),_c('th',{staticClass:"text-left"},[_vm._v("Acciones")])])]),_c('tbody',_vm._l((_vm.commentsFacebook),function(commentFacebook,idx){return _c('tr',{key:idx},[_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(commentFacebook.updatedAt)))]),_c('td',[_c('a',{attrs:{"href":commentFacebook.postUrl,"target":"_blank"}},[_vm._v(_vm._s(commentFacebook.postUrl))])]),_c('td',[_c('v-btn',{attrs:{"outlined":"","color":"secondary","text":""},on:{"click":function($event){return _vm.assignCommentWithoutResponse(commentFacebook)}}},[_vm._v("Asignar")])],1)])}),0)]},proxy:true}])})],1)],1)],1),_c('v-divider'),_c('v-container',{staticClass:"pa-5"}),_c('v-card-actions',{attrs:{"rd-actions":""}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"outlined":"","color":"error","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }